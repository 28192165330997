$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.NotFoundPage {

  &__Content {

    margin-top: $spacing * 4;
    padding: $spacing * 2;
    border-radius: $radius;
    box-shadow: none;
    border: 1px solid grey;

    .Title, p {
      font-weight: lighter;
    }

    @include isMobileDevice {
      padding: $spacing;

      .Title {
        font-size: 24px;
      }

      p {
        font-size: 12px;
      }
    }
  }
  
}